import React, { useState, Fragment, useRef } from "react";
import { NavLink } from "react-router-dom";
import dateFormat from 'dateformat';
import {  i18n  }  from  "dateformat" ;
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css'
import Select from 'react-select';
import axios from 'axios';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

import AccordionOffer from "../../component/Other/AccordionOffer";
import Footer from "../../component/Other/Footer";
import Navbar from "../../component/Other/Navbar";
import Navstep from "../../component/Other/Navstep";
import ReviewTravel from "../../component/Other/ReviewTravel";

const Detail = (props) => {
    const healingData =
        {
            title:"힐링/파티룸",
            subTitle:"모임, 생일파티, 행사, 시연회, PT장 등으로 대관 가능",
            criteria:"기준인원 4명",
            max:"최대 15~20인",
            offer:"빔프로젝터/스피커/마이크",
            content: [
                "*실평수 20평형 규모로 빈백 및 1인용 소파베드를 포함해 15인 규모의 좌석 세팅이 가능합니다.",
                "*150인치 대형스크린과 고해상도 빔프로젝터를 활용하실 수 있으며 보드게임, 담요 등을 무료로 대여해드리며 룸 내에 빔과 연결된 노트북이 비치되어 있어서 바로 활용하실 수 있습니다.",
                "*파티, PT, 행사, 영화상영, 모임 등 어떤 형태로도 활용할 수 있으며 미리 이용목적을 알려주시면 원활한 행사를 진행하실 수 있도록 지원해 드립니다.",
                "*레이아웃 변경을 원할 시, 요청사항에 적어주세요. 단, 기준 이용금액 미달시 레이아웃 변경이 불가할 수 있습니다."
            ],
            imgSrc:[
                {original:"./../images/healing/_h2.jpg", thumbnail:"./../images/healing/_h2.jpg"},
                {original:"./../images/healing/h1.jpg", thumbnail:"./../images/healing/h1.jpg"},
                {original:"./../images/healing/h3.jpg", thumbnail:"./../images/healing/h3.jpg"},
                {original:"./../images/healing/m1.jpg", thumbnail:"./../images/healing/m1.jpg"},
                {original:"./../images/healing/m2.jpg", thumbnail:"./../images/healing/m2.jpg"},
                {original:"./../images/healing/m3.jpg", thumbnail:"./../images/healing/m3.jpg"},
                {original:"./../images/healing/m5.jpg", thumbnail:"./../images/healing/m5.jpg"},
                {original:"./../images/healing/m6.jpg", thumbnail:"./../images/healing/m6.jpg"}
            ],
            peopleCountData: [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
            appointedPay: [15000, 20000]
        };
    const healingAccordions = [
        {
            icon: "./../images/list (8).png",
            title: "빔프로젝터 및 스크린",
            detail: [
                "150인치 영화관 수준의 고해상도 빔프로젝트 및 스크린 보유"
            ]
        },
        {
            icon: "./../images/list (1).png",
            title: "고성능 스피커",
            detail: [
                "최대출력 60W급 고성능 스피커"
            ]
        },
        {
            icon: "./../images/list (4).png",
            title: "무료 음료(티) 제공",
            detail: [
                "따듯한 아메리카노",
                "5가지 종류 이상의 티"
            ]
        },
        {
            icon: "./../images/list (9).png",
            title: "노트북 대여",
            detail: [
                "룸 내 비치된 노트북 사용 가능"
            ]
        },
        {
            icon: "./../images/list (3).png",
            title: "무료 대여",
            detail: [
                "키보드/마우스 (사전신청)",
                "필기/문구류",
                "충전케이블",
                "20여종의 보드게임",
            ]
        },
        {
            icon: "./../images/list (6).png",
            title: "와이파이",
            detail: [
                "와이파이 사용 가능"
            ]
        },
        {
            icon: "./../images/list (5).png",
            title: "빈백 및 스톨/소파베드",
            detail: [
                "눕거나 앉아서 쉴 수 있는 빈백 및 스툴과 1인용 소파베드"
            ]
        },
    ];

    const cafeData = {
            title:"카페룸",
            subTitle:"60인치 TV/모니터 이용가능",
            criteria:"기준인원 6명",
            max:"최대 20인",
            offer:"TV/모니터",
            content: [
                "*60인치 이상의 대형 TV를 보유하고 있으며, 세미나, 다이닝, 회의실 등 최대 26인까지 이용하실 수 있는 공간입니다.",
                "*좌석수로는 최대 26인까지 이용하실 수 있지만 여유롭게 이용하기 좋은 인원은 최대 20명입니다.",
                "*파티, PT, 행사, 영화상영, 모임 등 어떤 형태로도 활용할 수 있으며 미리 이용목적을 알려주시면 원활한 행사를 진행하실 수 있도록 지원해 드립니다.",
                "*레이아웃 변경을 원할 시, 요청사항에 적어주세요. 단, 기준 이용금액 미달시 레이아웃 변경이 불가할 수 있습니다."
            ],
            imgSrc:[
                {original:"./../images/cafe/c1.jpg", thumbnail:"./../images/cafe/c1.jpg"},
                {original:"./../images/cafe/c2.jpg", thumbnail:"./../images/cafe/c2.jpg"},
                {original:"./../images/cafe/m1.jpg", thumbnail:"./../images/cafe/m1.jpg"},
                {original:"./../images/cafe/m2.jpg", thumbnail:"./../images/cafe/m2.jpg"},
                {original:"./../images/cafe/m3.jpg", thumbnail:"./../images/cafe/m3.jpg"},
                {original:"./../images/cafe/m4.jpg", thumbnail:"./../images/cafe/m4.jpg"}
            ],
            peopleCountData: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
            appointedPay: [15000, 15000]
        };
    const cafeAccordions = [
        {
            icon: "./../images/list (8).png",
            title: "TV/모니터",
            detail: [
                "60인치 이상의 이동식 대형 모니터 보유"
            ]
        },
        {
            icon: "./../images/list (4).png",
            title: "무료 음료(티) 제공",
            detail: [
                "따듯한 아메리카노",
                "5가지 종류 이상의 티"
            ]
        },
        {
            icon: "./../images/list (3).png",
            title: "무료 대여",
            detail: [
                "키보드/마우스 (사전신청)",
                "필기/문구류",
                "충전케이블",
                "20여종의 보드게임",
            ]
        },
        {
            icon: "./../images/list (6).png",
            title: "와이파이",
            detail: [
                "와이파이 사용 가능"
            ]
        }
    ]

    const r1Data = {
            title: "8인룸 R1",
            subTitle:"3인 이상 예약 및 사용가능",
            criteria:"기준인원 3명",
            max:"최대 8인",
            offer:"TV/모니터",
            content: [
                "*최대 8인까지 입장 가능한 미팅룸입니다. 미팅 룸 내 회의, 업무, 팀플, 스터디 등이 가능하며 보드게임 대여와 플레이도 가능합니다.",
                "*화이트보드와 연결 가능한 40인치 이상의 모니터가 설치되어 있습니다.",
                "*예약 시 키보드, 마우스, 필기도구, 담요, 보드게임을 무료로 대여해 드립니다."
            ],
            imgSrc:[
                {original:"./../images/r1/_m1.jpg", thumbnail:"./../images/r1/_m1.jpg"},
                {original:"./../images/r1/m2.jpg", thumbnail:"./../images/r1/m2.jpg"},
                {original:"./../images/r1/m3.jpg", thumbnail:"./../images/r1/m3.jpg"},
                {original:"./../images/r1/m4.jpg", thumbnail:"./../images/r1/m4.jpg"}
            ],
            peopleCountData: [3, 4, 5, 6, 7, 8],
            appointedPay: [1500, 2000]
        };
    const r1Accordions = [
        {
            icon: "./../images/list (8).png",
            title: "TV/모니터",
            detail: [
                "40인치 이상의 모니터 보유"
            ]
        },
        {
            icon: "./../images/list (4).png",
            title: "무료 음료(티) 제공",
            detail: [
                "따듯한 아메리카노",
                "5가지 종류 이상의 티"
            ]
        },
        {
            icon: "./../images/list (3).png",
            title: "무료 대여",
            detail: [
                "키보드/마우스 (사전신청)",
                "필기/문구류",
                "충전케이블",
                "20여종의 보드게임",
            ]
        },
        {
            icon: "./../images/list (9).png",
            title: "화이트보드",
            detail: [
                "색상별 보드마커와 지우개 제공"
            ]
        },
        {
            icon: "./../images/list (6).png",
            title: "와이파이",
            detail: [
                "와이파이 사용 가능"
            ]
        }
    ]

    const r2Data = {
            title: "8인룸 R2",
            subTitle:"3인 이상 예약 및 사용가능",
            criteria:"기준인원 3명",
            max:"최대 8인",
            offer:"TV/모니터",
            content: [
                "*최대 8인까지 입장 가능한 미팅룸입니다. 미팅 룸 내 회의, 업무, 팀플, 스터디 등이 가능하며 보드게임 대여와 플레이도 가능합니다.",
                "*화이트보드, 이동 가능한 30인치 모니터와 빔프로젝터가 설치되어 있습니다.",
                "*예약 시 키보드, 마우스, 필기도구, 담요, 보드게임을 무료로 대여해 드립니다."
            ],
            imgSrc:[
                {original:"./../images/r2/_m1.jpg", thumbnail:"./../images/r2/_m1.jpg"},
                {original:"./../images/r2/m2.jpg", thumbnail:"./../images/r2/m2.jpg"},
                {original:"./../images/r2/m3.jpg", thumbnail:"./../images/r2/m3.jpg"},
                {original:"./../images/r2/m4.jpg", thumbnail:"./../images/r2/m4.jpg"}
            ],
            peopleCountData: [3, 4, 5, 6, 7, 8],
            appointedPay: [1500, 2000]
        };
    const r2Accordions = [
        {
            icon: "./../images/list (8).png",
            title: "TV/모니터",
            detail: [
                "이동 가능한 30인치 모니터와 빔프로젝터 보유"
            ]
        },
        {
            icon: "./../images/list (4).png",
            title: "무료 음료(티) 제공",
            detail: [
                "따듯한 아메리카노",
                "5가지 종류 이상의 티"
            ]
        },
        {
            icon: "./../images/list (3).png",
            title: "무료 대여",
            detail: [
                "키보드/마우스 (사전신청)",
                "필기/문구류",
                "충전케이블",
                "20여종의 보드게임",
            ]
        },
        {
            icon: "./../images/list (9).png",
            title: "화이트보드",
            detail: [
                "색상별 보드마커와 지우개 제공"
            ]
        },
        {
            icon: "./../images/list (6).png",
            title: "와이파이",
            detail: [
                "와이파이 사용 가능"
            ]
        }
    ]

    const r3Data =
        {
            title:"4인룸 R3",
            subTitle:"최소 2인이상 이용 가능(개인 이용 시에는 2인요금 부과)",
            criteria:"기준인원 2명",
            max:"최대 4인",
            offer:"스마트TV/모니터",
            content: [
                "*최대 4인까지 입장 가능한 미팅룸입니다. 미팅 룸 내 회의, 업무, 팀플, 스터디 등이 가능하며 보드게임 대여와 플레이도 가능합니다.",
                "*각 룸 블라인드 설치되어 프라이빗한 환경을 제공합니다.",
                "*화이트보드와 연결 가능한 30인치의 스마트TV/모니터가 설치되어 있습니다.",
                "*예약 시 키보드, 마우스, 필기도구, 담요, 보드게임을 무료로 대여해 드립니다."
            ],
            imgSrc:[
                {original:"./../images/r3/m2.jpg", thumbnail:"./../images/r3/m2.jpg"},
                {original:"./../images/r3/_m3.jpg", thumbnail:"./../images/r3/_m3.jpg"},
                {original:"./../images/r3/m1.jpg", thumbnail:"./../images/r3/m1.jpg"}
            ],
            peopleCountData: [2, 3, 4],
            appointedPay: [1500, 2000]
        };
    const r3Accordions =[
        {
            icon: "./../images/list (8).png",
            title: "TV/모니터",
            detail: [
                "30인치 스마트TV/모니터"
            ]
        },
        {
            icon: "./../images/list (4).png",
            title: "무료 음료(티) 제공",
            detail: [
                "따듯한 아메리카노",
                "5가지 종류 이상의 티"
            ]
        },
        {
            icon: "./../images/list (3).png",
            title: "무료 대여",
            detail: [
                "키보드/마우스 (사전신청)",
                "필기/문구류",
                "충전케이블",
                "20여종의 보드게임",
            ]
        },
        {
            icon: "./../images/list (9).png",
            title: "화이트보드",
            detail: [
                "색상별 보드마커와 지우개 제공"
            ]
        },
        {
            icon: "./../images/list (6).png",
            title: "와이파이",
            detail: [
                "와이파이 사용 가능"
            ]
        }
    ];

    const r4Data =
        {
            title:"4인룸 R4",
            subTitle:"최소 2인이상 이용 가능(개인 이용 시에는 2인요금 부과)",
            criteria:"기준인원 2명",
            max:"최대 4인",
            offer:"스마트TV/모니터",
            content: [
                "*최대 4인까지 입장 가능한 미팅룸입니다. 미팅 룸 내 회의, 업무, 팀플, 스터디 등이 가능하며 보드게임 대여와 플레이도 가능합니다.",
                "*각 룸 블라인드 설치되어 프라이빗한 환경을 제공합니다.",
                "*화이트보드와 연결 가능한 30인치의 스마트TV/모니터가 설치되어 있습니다.",
                "*예약 시 키보드, 마우스, 필기도구, 담요, 보드게임을 무료로 대여해 드립니다."
            ],
            imgSrc:[
                {original:"./../images/r4/m2.jpg", thumbnail:"./../images/r4/m2.jpg"},
                {original:"./../images/r4/_m3.jpg", thumbnail:"./../images/r4/_m3.jpg"},
                {original:"./../images/r4/m1.jpg", thumbnail:"./../images/r4/m1.jpg"}
            ],
            peopleCountData: [2, 3, 4],
            appointedPay: [1500, 2000]
        };
    const r4Accordions =[
        {
            icon: "./../images/list (8).png",
            title: "TV/모니터",
            detail: [
                "30인치 스마트TV/모니터"
            ]
        },
        {
            icon: "./../images/list (4).png",
            title: "무료 음료(티) 제공",
            detail: [
                "따듯한 아메리카노",
                "5가지 종류 이상의 티"
            ]
        },
        {
            icon: "./../images/list (3).png",
            title: "무료 대여",
            detail: [
                "키보드/마우스 (사전신청)",
                "필기/문구류",
                "충전케이블",
                "20여종의 보드게임",
            ]
        },
        {
            icon: "./../images/list (9).png",
            title: "화이트보드",
            detail: [
                "색상별 보드마커와 지우개 제공"
            ]
        },
        {
            icon: "./../images/list (6).png",
            title: "와이파이",
            detail: [
                "와이파이 사용 가능"
            ]
        }
    ];

    const r5Data =
        {
            title:"10인룸 VIP(R5)",
            subTitle:"모임, 다이닝, 영화관 등 다양한 형태로 대관 가능",
            criteria:"기준인원 4명",
            max:"최대 10인",
            offer:"스마트TV/빔프로젝터",
            content: [
                "*편안한 소파와 대형 TV,  빔프로젝트/스크린 사용이 가능하며 회의실, 다이닝룸, 작은 영화관 등의 용도로 사용하실 수 있는 공간입니다.",
                "*예약 시 키보드, 마우스, 필기도구, 담요, 보드게임을 무료로 대여해 드립니다.",
            ],
            imgSrc:[
                {original:"./../images/r5/m1.jpg", thumbnail:"./../images/r5/m1.jpg"},
                {original:"./../images/r5/m2.jpg", thumbnail:"./../images/r5/m2.jpg"},
                {original:"./../images/r5/m3.jpg", thumbnail:"./../images/r5/m3.jpg"},
                {original:"./../images/r5/m4.jpg", thumbnail:"./../images/r5/m4.jpg"},
                {original:"./../images/r5/m5.jpg", thumbnail:"./../images/r5/m5.jpg"}
            ],
            peopleCountData: [4, 5, 6, 7, 8, 9, 10],
            appointedPay: [12000, 15000]
        };
    const r5Accordions = [
        {
            icon: "./../images/list (8).png",
            title: "스마트TV/모니터",
            detail: [
                "이동 가능한 30인치 스마트TV/모니터와 빔프로젝터 보유"
            ]
        },
        {
            icon: "./../images/list (4).png",
            title: "무료 음료(티) 제공",
            detail: [
                "따듯한 아메리카노",
                "5가지 종류 이상의 티"
            ]
        },
        {
            icon: "./../images/list (3).png",
            title: "무료 대여",
            detail: [
                "키보드/마우스 (사전신청)",
                "필기/문구류",
                "충전케이블",
                "20여종의 보드게임",
            ]
        },
        {
            icon: "./../images/list (9).png",
            title: "화이트보드",
            detail: [
                "색상별 보드마커와 지우개 제공"
            ]
        },
        {
            icon: "./../images/list (6).png",
            title: "와이파이",
            detail: [
                "와이파이 사용 가능"
            ]
        }
    ]

    const r6Data =
        {
            title:"12인룸 R6",
            subTitle:"*최대 12명이 이용하실 수 있습니다",
            criteria:"기준인원 6명",
            max:"최대 12인",
            offer:"TV/모니터",
            content: [
                "*12인이 입장 가능한 룸으로 미팅, 다이닝, 세미나 등의 용도로 사용 가능한 공간입니다.",
                "*예약 시 키보드, 마우스, 필기도구, 담요, 보드게임을 무료로 대여해 드립니다."
            ],
            imgSrc:[
                {original:"./../images/r6/_r6.jpg", thumbnail:"./../images/r6/_r6.jpg"},
                {original:"./../images/r6/m1.jpg", thumbnail:"./../images/r6/m1.jpg"},
                {original:"./../images/r6/m3.jpg", thumbnail:"./../images/r6/m3.jpg"}
            ],
            peopleCountData: [6, 7, 8, 9, 10, 11, 12],
            appointedPay: [1500, 2000]
        };
    const r6Accordions = [
        {
            icon: "./../images/list (8).png",
            title: "TV/모니터",
            detail: [
                "이동 가능한 60인치 모니터와 빔프로젝터 보유"
            ]
        },
        {
            icon: "./../images/list (4).png",
            title: "무료 음료(티) 제공",
            detail: [
                "따듯한 아메리카노",
                "5가지 종류 이상의 티"
            ]
        },
        {
            icon: "./../images/list (3).png",
            title: "무료 대여",
            detail: [
                "키보드/마우스 (사전신청)",
                "필기/문구류",
                "충전케이블",
                "20여종의 보드게임",
            ]
        },
        {
            icon: "./../images/list (9).png",
            title: "화이트보드",
            detail: [
                "색상별 보드마커와 지우개 제공"
            ]
        },
        {
            icon: "./../images/list (6).png",
            title: "와이파이",
            detail: [
                "와이파이 사용 가능"
            ]
        }
    ]

    const r7Data =
        {
            title:"4인룸 R7",
            subTitle:"최소 2인이상 이용 가능(개인 이용 시에는 2인요금 부과)",
            criteria:"기준인원 2명",
            max:"최대 4인",
            offer:"스마트TV/모니터",
            content: [
                "*최대 4인까지 입장 가능한 미팅룸입니다. 미팅 룸 내 회의, 업무, 팀플, 스터디 등이 가능하며 보드게임 대여와 플레이도 가능합니다.",
                "*각 룸 블라인드 설치되어 프라이빗한 환경을 제공합니다.",
                "*화이트보드와 연결 가능한 30인치의 스마트TV/모니터가 설치되어 있습니다.",
                "*예약 시 키보드, 마우스, 필기도구, 담요, 보드게임을 무료로 대여해 드립니다."
            ],
            imgSrc:[
                {original:"./../images/r7/_m2.jpg", thumbnail:"./../images/r7/_m2.jpg"},
                {original:"./../images/r7/m3.jpg", thumbnail:"./../images/r7/m3.jpg"},
                {original:"./../images/r7/m1.jpg", thumbnail:"./../images/r7/m1.jpg"}
            ],
            peopleCountData: [2, 3, 4],
            appointedPay: [1500, 2000]
        };
    const r7Accordions =[
        {
            icon: "./../images/list (8).png",
            title: "TV/모니터",
            detail: [
                "30인치 스마트TV/모니터"
            ]
        },
        {
            icon: "./../images/list (4).png",
            title: "무료 음료(티) 제공",
            detail: [
                "따듯한 아메리카노",
                "5가지 종류 이상의 티"
            ]
        },
        {
            icon: "./../images/list (3).png",
            title: "무료 대여",
            detail: [
                "키보드/마우스 (사전신청)",
                "필기/문구류",
                "충전케이블",
                "20여종의 보드게임",
            ]
        },
        {
            icon: "./../images/list (9).png",
            title: "화이트보드",
            detail: [
                "색상별 보드마커와 지우개 제공"
            ]
        },
        {
            icon: "./../images/list (6).png",
            title: "와이파이",
            detail: [
                "와이파이 사용 가능"
            ]
        }
    ];

    const r8Data =
        {
            title: "8인룸 R8",
            subTitle:"3인 이상 예약 및 사용가능",
            criteria:"기준인원 3명",
            max:"최대 8인",
            offer:"TV/모니터",
            content: [
                "*최대 8인까지 입장 가능한 미팅룸입니다. 미팅 룸 내 회의, 업무, 팀플, 스터디 등이 가능하며 보드게임 대여와 플레이도 가능합니다.",
                "*화이트보드와 이동 가능한 30인치 스마트TV/모니터가 설치되어 있습니다.",
                "*예약 시 키보드, 마우스, 필기도구, 담요, 보드게임을 무료로 대여해 드립니다."
            ],
            imgSrc:[
                {original:"./../images/r8/_m2.jpg", thumbnail:"./../images/r8/_m2.jpg"},
                {original:"./../images/r8/m1.jpg", thumbnail:"./../images/r8/m1.jpg"},
                {original:"./../images/r8/_m2.jpg", thumbnail:"./../images/r8/_m2.jpg"}
            ],
            peopleCountData: [3, 4, 5, 6, 7, 8],
            appointedPay: [1500, 2000]
        };
    const r8Accordions = [
        {
            icon: "./../images/list (8).png",
            title: "TV/모니터",
            detail: [
                "이동 가능한 30인치 모니터"
            ]
        },
        {
            icon: "./../images/list (4).png",
            title: "무료 음료(티) 제공",
            detail: [
                "따듯한 아메리카노",
                "5가지 종류 이상의 티"
            ]
        },
        {
            icon: "./../images/list (3).png",
            title: "무료 대여",
            detail: [
                "키보드/마우스 (사전신청)",
                "필기/문구류",
                "충전케이블",
                "20여종의 보드게임",
            ]
        },
        {
            icon: "./../images/list (9).png",
            title: "화이트보드",
            detail: [
                "색상별 보드마커와 지우개 제공"
            ]
        },
        {
            icon: "./../images/list (6).png",
            title: "와이파이",
            detail: [
                "와이파이 사용 가능"
            ]
        }
    ]

    const mYellowData =
        {
            title:"미니룸(옐로우)",
            subTitle:"좌식룸으로 신발벗고 이용하시는룸 입니다.",
            criteria:"기준인원 1명",
            max:"최대 2인",
            offer:"대여품 무료 제공",
            content: [
                "*삼각/방석쿠션이 배치되어있어 편하게 이용하실 수 있으며 보드게임 대여와 플레이도 가능합니다.",
                "*예약 시 키보드, 마우스, 필기도구, 담요, 보드게임을 무료로 대여해 드립니다."
            ],
            imgSrc:[
                {original:"./../images/mini/m1.jpg", thumbnail:"./../images/mini/m1.jpg"},
                {original:"./../images/mini/_m2.jpg", thumbnail:"./../images/mini/_m2.jpg"},
                {original:"./../images/mini/m3.jpg", thumbnail:"./../images/mini/m3.jpg"}
            ],
            peopleCountData: [1, 2],
            appointedPay: [1500, 2000]
        };
    const mYellowAccordions = [
        {
            icon: "./../images/list (4).png",
            title: "무료 음료(티) 제공",
            detail: [
                "따듯한 아메리카노",
                "5가지 종류 이상의 티"
            ]
        },
        {
            icon: "./../images/list (3).png",
            title: "무료 대여",
            detail: [
                "키보드/마우스 (사전신청)",
                "필기/문구류",
                "충전케이블",
                "20여종의 보드게임",
            ]
        },
        {
            icon: "./../images/list (9).png",
            title: "화이트보드",
            detail: [
                "색상별 보드마커와 지우개 제공"
            ]
        },
        {
            icon: "./../images/list (6).png",
            title: "와이파이",
            detail: [
                "와이파이 사용 가능"
            ]
        }
    ];

    const mOrangeData =
        {
            title:"미니룸(오렌지)",
            subTitle:"좌식룸으로 신발벗고 이용하시는룸 입니다.",
            criteria:"기준인원 1명",
            max:"최대 2인",
            offer:"대여품 무료 제공",
            content: [
                "*삼각/방석쿠션이 배치되어있어 편하게 이용하실 수 있으며 보드게임 대여와 플레이도 가능합니다.",
                "*예약 시 키보드, 마우스, 필기도구, 담요, 보드게임을 무료로 대여해 드립니다."
            ],
            imgSrc:[
                {original:"./../images/mini/m4.jpg", thumbnail:"./../images/mini/m4.jpg"},
                {original:"./../images/mini/_m5.jpg", thumbnail:"./../images/mini/_m5.jpg"},
                {original:"./../images/mini/m6.jpg", thumbnail:"./../images/mini/m6.jpg"}
            ],
            peopleCountData: [1, 2],
            appointedPay: [1500, 2000]
        };
    const mOrangeAccordions = [
        {
            icon: "./../images/list (4).png",
            title: "무료 음료(티) 제공",
            detail: [
                "따듯한 아메리카노",
                "5가지 종류 이상의 티"
            ]
        },
        {
            icon: "./../images/list (3).png",
            title: "무료 대여",
            detail: [
                "키보드/마우스 (사전신청)",
                "필기/문구류",
                "충전케이블",
                "20여종의 보드게임",
            ]
        },
        {
            icon: "./../images/list (9).png",
            title: "화이트보드",
            detail: [
                "색상별 보드마커와 지우개 제공"
            ]
        },
        {
            icon: "./../images/list (6).png",
            title: "와이파이",
            detail: [
                "와이파이 사용 가능"
            ]
        }
    ];

    const mBlueData =
        {
            title:"미니룸(블루)",
            subTitle:"좌식룸으로 신발벗고 이용하시는룸 입니다.",
            criteria:"기준인원 1명",
            max:"최대 2인",
            offer:"대여품 무료 제공",
            content: [
                "*삼각/방석쿠션이 배치되어있어 편하게 이용하실 수 있으며 보드게임 대여와 플레이도 가능합니다.",
                "*예약 시 키보드, 마우스, 필기도구, 담요, 보드게임을 무료로 대여해 드립니다."
            ],
            imgSrc:[
                {original:"./../images/mini/m7.jpg", thumbnail:"./../images/mini/m7.jpg"},
                {original:"./../images/mini/_m8.jpg", thumbnail:"./../images/mini/_m8.jpg"},
                {original:"./../images/mini/m9.jpg", thumbnail:"./../images/mini/m9.jpg"}
            ],
            peopleCountData: [1, 2],
            appointedPay: [1500, 2000]
        };
    const mBlueAccordions = [
        {
            icon: "./../images/list (4).png",
            title: "무료 음료(티) 제공",
            detail: [
                "따듯한 아메리카노",
                "5가지 종류 이상의 티"
            ]
        },
        {
            icon: "./../images/list (3).png",
            title: "무료 대여",
            detail: [
                "키보드/마우스 (사전신청)",
                "필기/문구류",
                "충전케이블",
                "20여종의 보드게임",
            ]
        },
        {
            icon: "./../images/list (9).png",
            title: "화이트보드",
            detail: [
                "색상별 보드마커와 지우개 제공"
            ]
        },
        {
            icon: "./../images/list (6).png",
            title: "와이파이",
            detail: [
                "와이파이 사용 가능"
            ]
        }
    ];

    i18n . dayNames  =  [
      "일" ,
      "월" ,
      "화" ,
      "수" ,
      "목" ,
      "금" ,
      "토"
    ] ;

    i18n . monthNames  =  [
      "1월" ,
      "2월" ,
      "3월" ,
      "4월" ,
      "5월" ,
      "6월" ,
      "7월" ,
      "8월" ,
      "9월" ,
      "10월" ,
      "11월" ,
      "12월"
    ] ;

    const getParameter = (key) => {
      return new URLSearchParams(window.location.search).get(key);
    };

    const numberFormatter = (num) => {
        var regexp = /\B(?=(\d{3})+(?!\d))/g;
        return num.toString().replace(regexp, ',');
    }

    const roomId    = getParameter("id");
    let roomName    = "미팅룸";
    let data        = null;
    let accordions  = null;

    switch(roomId) {
        case 'healing' :
            accordions = healingAccordions;
            data       = healingData;
            roomName   = "힐링룸";
            break;
        case 'cafe' :
            accordions = cafeAccordions;
            data       = cafeData;
            roomName   = "카페룸";
            break;
        /*case 'r1' :
            accordions = r1Accordions;
            data = r1Data;
            roomName = "r6"
            break;*/
        case 'r2' :
            accordions = r2Accordions;
            data = r2Data;
            break;
        case 'r3' :
            accordions = r3Accordions;
            data = r3Data;
            break;
        case 'r4' :
            accordions = r4Accordions;
            data = r4Data;
            break;
        case 'r5' :
            accordions = r5Accordions;
            data = r5Data;
            break;
        case 'r6' :
            accordions = r6Accordions;
            data = r6Data;
            break;
        case 'r7' :
            accordions = r7Accordions;
            data = r7Data;
            break;
        case 'r8' :
            accordions = r8Accordions;
            data = r8Data;
            break;
        /*case 'mYellow' :
            accordions = mYellowAccordions;
            data = mYellowData;
            break;
        case 'mOrange' :
            accordions = mOrangeAccordions;
            data = mOrangeData;
            break;
        case 'mBlue' :
            accordions = mBlueAccordions;
            data = mBlueData;
            break;*/
    }

    let minUsers   = data.peopleCountData[0];

    let selectOptions = {
      start: [],
      hour: [],
      bigRoomHour: []
    }
    for (var i=0;i<12;i++) {
        selectOptions.hour.push({value:(1+i)+":00", label:(1+i)+"시간"});
        if (i < 11) selectOptions.hour.push({value:(1+i)+":30", label:(1+i)+"시간 30분"});
        if (i < 10) selectOptions.bigRoomHour.push({value:(2+i)+":00", label:(2+i)+"시간"});
        if (i < 10) selectOptions.bigRoomHour.push({value:(2+i)+":30", label:(2+i)+"시간 30분"});
    }

    for(var i=10;i<25;i++) {
        selectOptions.start.push({value:i+":00", label:i+"시"});
        selectOptions.start.push({value:i+":30", label:i+"시 30분"});
    }

    // timeConverter ('10:00', true/false)
    const timeConverter = (t, b) => {
        const ts = t.split(":");
        return ts[0]+"시"+(b ? "간" : "")+(ts[0] == "30" ? " 30분" : "");
    }

    const chkBigRoom = (r) => {
        if(r == "카페룸" || r == "힐링/파티룸" || r == "10인룸 VIP(R5)") {
            return true;
        } else { return false }
    }

    const datein = useRef()
    const [defaultPrice, setDefaultPrice] = useState();

    const [inValue, setInValue] = useState(dateFormat(Date.now(), "mmm dd일 (ddd)"));
    const [inValueTime, setInValueTime] = useState(new Date().setHours(0,0,0,0,0,0));

    const dateout = useRef()
    const [outValue, setOutValue] = useState(dateFormat(Date.now(), "mmm dd일 (ddd)"));

    const [toogleGues, setToogleGues] = useState(false);
    const [gues, setGues] = useState(minUsers);
    const [startTime, setStartTime] = useState((new Date().getHours()+1)+":00");
    const [useHours, setUseHours] = useState("1:00");
    const [likeValue, setLikeValue] = useState(0);
    const [likeStatus, setLikeStatus] = useState(localStorage.getItem("like_"+roomId));

    axios.post(
      'https://primesheet.net/query/call',
      JSON.stringify({
        id:"63c4ff95be755ed0cad9ed4a",
        params: {
            room_id: roomId
        }
      }),
      {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    })
    .then(function (response) {
        setLikeValue(response.data.result.like);
    });

    const handleCopyClipBoard = (text: string) => {
      try {
        navigator.clipboard.writeText(text);
        alert('클립보드에 복사되었습니다. \n원하는 곳에 붙여넣기 해주세요.(Ctrl+V)');
      } catch (error) {
        alert('클립보드 복사에 실패하였습니다.');
      }
    };


    const convertHour = (useHours) => {
        let checkHours = useHours.split(":");
        let convertHourValue = parseInt(checkHours[0]) + (parseInt(checkHours[1]) == 30 ? 0.5 : 0);

        return convertHourValue
    }
    const [convertHours, setConvertHours] = useState(1);

    //처음 초기 세팅 값 계산
    const basicPriceCalc = () => {
        // Logic
        let basic = new Date(inValueTime).getDay() == 0 || new Date(inValueTime).getDay() == 6 ? data.appointedPay[1] : data.appointedPay[0];
        let sum = basic * ((roomId == 'healing' || roomId == 'cafe' || roomId == 'r5' ) ? 1 : gues) * convertHours;
        return sum
    }

    const [basicPrice, setBasicPrice] = useState(numberFormatter(basicPriceCalc()));
    const [addPeoplePrice, setAddPeoplePrice] = useState(0);
    const [totalPrice, setTotalPrice] = useState(numberFormatter(basicPriceCalc()));

    const calcPrice = (selectedValue, startTime, inValueTime, useHours) => {
        //공휴일일 경우 계산
        const redDate = ['2023-09-28', '2023-09-29', '2023-09-30', '2023-10-01', '2023-10-02', '2023-10-03', '2023-10-09', '2023-12-25', '2024-01-01'];
        let redDay    = false;
    
        for(let i=0;i<redDate.length;i++) {
            let reservDate = inValueTime;
            if (reservDate == new Date(redDate[i]).setHours(0,0,0,0,0,0)) {
                redDay = true;
                break;
            }
        }
        console.log(redDay)
        let
          book_type       = data.title
        , book_date       = new Date(inValueTime).setHours(0,0,0,0,0,0)
        , check_day       = new Date(book_date).getDay()
        , appointed_pay   = (check_day == 0 || check_day == 6 || redDay == true)? data.appointedPay[1] : data.appointedPay[0]
        , elapsed_hours   = useHours
        , checkHours      = elapsed_hours.split(':')
        , using_people    = selectedValue
        , finalPrice      = null
        , final_price     = null
        , _basicPrice     = null
        , _addPeoplePrice     = null
        ;

        const priceCalculation = (usingPeople, appointedPay, checkHours, criteria, addPrice, roomType) => {
            if(roomType == 'small'){
                if(checkHours[1] == '00'){
                    _basicPrice     = criteria * appointedPay * checkHours[0];
                    _addPeoplePrice = (usingPeople-criteria) * appointedPay * checkHours[0];
                    finalPrice     = _basicPrice + _addPeoplePrice;
                } else if(checkHours[1] == '30'){
                    _basicPrice     = criteria * appointedPay * checkHours[0] + (criteria * 1000);
                    _addPeoplePrice = (usingPeople-criteria) * appointedPay * checkHours[0] + ((usingPeople-criteria) * 1000);
                    finalPrice     = _basicPrice + _addPeoplePrice;
                }

                if(roomId == 'healing' || roomId == 'cafe' || roomId == 'r5' && usingPeople <= criteria) {
                    document.querySelector('.add-people-price').style.display = "block";
                    setAddPeoplePrice(numberFormatter(_addPeoplePrice));
                } else {
                    document.querySelector('.add-people-price').style.display = "none";
                }
                /*if(usingPeople <= criteria){
                    document.querySelector('.add-people-price').style.display = "none";
                } else {
                    setAddPeoplePrice(numberFormatter(addPeoplePrice));
                    document.querySelector('.add-people-price').style.display = "block";
                }*/

                setBasicPrice(numberFormatter(_basicPrice));
                setTotalPrice(numberFormatter(finalPrice));

                return finalPrice
            } else {
                const totalHours = parseInt(checkHours[0]) + (parseInt(checkHours[1]) == 30 ? 0.5 : 0);

                let finalPrice   = 0;
                _basicPrice       = appointedPay * totalHours;
                _addPeoplePrice   = (usingPeople - criteria) * addPrice * totalHours;

                if(usingPeople <= criteria){
                    finalPrice = _basicPrice;
                    document.querySelector('.add-people-price').style.display = "none";
                } else {
                    finalPrice = _basicPrice + _addPeoplePrice;
                    setAddPeoplePrice(numberFormatter(_addPeoplePrice));
                    document.querySelector('.add-people-price').style.display = "block";
                }

                setBasicPrice(numberFormatter(_basicPrice));
                setTotalPrice(numberFormatter(finalPrice));

                return finalPrice
            }
        }

        switch(book_type){
            case '8인룸 R1':
            case '8인룸 R2':
            case '8인룸 R8':
                if(using_people > 8) {
                    alert('최대인원은 8명입니다.');
                    final_price = priceCalculation(8, appointed_pay, checkHours, null, null, 'small');
                } else {
                    // priceCalculation => (usingPeople, appointedPay, checkHours, criteria, addPrice, roomType);
                    final_price = priceCalculation((using_people < 3 ? 3 : using_people), appointed_pay, checkHours, 3, null, 'small');
                }
                break;
            case '4인룸 R3':
            case '4인룸 R4':
            case '4인룸 R7':
                if(using_people > 4) {
                    alert('최대인원은 4명입니다.');
                    final_price = priceCalculation(4, appointed_pay, checkHours, null, null, 'small');
                } else {
                    // priceCalculation => (usingPeople, appointedPay, checkHours, criteria, addPrice, roomType);
                    final_price = priceCalculation((using_people < 2 ? 2 : using_people), appointed_pay, checkHours, 2, null, 'small');
                }
                break;
            case '10인룸 VIP(R5)':
                if(using_people > 10) {
                    alert('최대인원은 10명입니다.');
                    final_price = priceCalculation(10, appointed_pay, checkHours, 4, 2000);
                } else {
                    // priceCalculation => (usingPeople, appointedPay, checkHours, criteria, addPrice, roomType);
                    final_price = priceCalculation((using_people < 4 ? 4 : using_people), appointed_pay, checkHours, 4, 2000);
                }
                break;
            case '12인룸 R6':
                if(using_people > 12) {
                    alert('최대인원은 12명입니다.');
                    final_price = priceCalculation(12, appointed_pay, checkHours, 6, 2000);
                } else {
                    // priceCalculation => (usingPeople, appointedPay, checkHours, criteria, addPrice, roomType);
                    final_price = priceCalculation((using_people < 6 ? 6 : using_people), appointed_pay, checkHours, 6, null, 'small');
                }
                break;
            case '미니룸(옐로우)':
            case '미니룸(블루)':
            case '미니룸(오렌지)':
                if(using_people > 2) {
                    alert('최대인원은 2명입니다.');
                    final_price = priceCalculation(2, appointed_pay, checkHours, null, null, 'small');
                } else {
                    // priceCalculation => (usingPeople, appointedPay, checkHours, criteria, addPrice, roomType);
                    final_price = priceCalculation(using_people, appointed_pay, checkHours, 2, null, 'small');
                }
                break;
            case '카페룸':
                if(using_people > 20) {
                    alert('최대인원은 20명입니다.');
                    final_price = priceCalculation(20, appointed_pay, checkHours, 6, 2000);
                } else {
                    // priceCalculation => (usingPeople, appointedPay, checkHours, criteria, addPrice, roomType);
                    final_price = priceCalculation((using_people < 6 ? 6 : using_people), appointed_pay, checkHours, 6, 2000);
                }
                break;
            case '힐링/파티룸':
                if(using_people > 20) {
                    alert('최대인원은 20명입니다.');
                    final_price = priceCalculation(20, appointed_pay, checkHours, 4, 3000);
                } else {
                    // priceCalculation => (usingPeople, appointedPay, checkHours, criteria, addPrice, roomType);
                    final_price = priceCalculation((using_people < 4 ? 4 : using_people), appointed_pay, checkHours, 4, 3000);
                }

                break;
        }

        setTotalPrice(numberFormatter(final_price));
    }


    return (
        <Fragment>
            <div className="overflow-hidden">
                <Navbar />

                <section>
                    <div className="container">

                        <Navstep step={"공간정보"} now={data.title} />

                        <div className="mt-5 mb-5 d-flex align-items-center flex-wrap flex-md-nowrap justify-content-between">
                            <div className="mb-4 mb-md-0">
                                <h2 className="semi-bold font__size--48 text__48-1024 text__48-md text__48-sm mb-3 ls-1">{data.title}</h2>
                                <div className="d-flex align-items-center">
                                    <div className="wrapper__rounded-green color__green semi-bold font__size--14 text__14-1024 ls-1">
                                        {data.criteria}
                                    </div>
                                    <span className="medium font__size--14 text__14-1024 mx-sm-3 mx-2 color__gray-2 ls-1">{data.max}</span>
                                    <div className="d-flex align-items-center pb__018">
                                        <img src="./../images/star.png" alt="" />
                                        {/*<span className="ml-2 medium font__size--16 text__16-1024">4.8</span>
                                        <span className="ml-2 medium font__size--16 text__16-1024 color__gray-2">(256 reviews)</span>*/}
                                    </div>
                                </div>
                            </div>
                            <div className="wrapper__media-share d-flex align-items-center">

                                <a href="https://naver.me/5UdVUaZI" target="_blank" id="map">
                                    <img src="./../images/rwe (3).png" alt="" />
                                </a>
                                <Tooltip anchorId="map" content="지도" />

                                <a href="#!" id="copy">
                                    <img src="./../images/rwe (2).png" alt="" onClick={() => {let link = window.location.href; handleCopyClipBoard(link)}} />
                                </a>
                                <Tooltip anchorId="copy" content="링크복사" />

                                <a href="#!" id="like" className="like-box" onClick={() => {
                                        if(!likeStatus) {
                                            localStorage.setItem("like_"+roomId, "1");
                                        } else {
                                            localStorage.removeItem("like_"+roomId);
                                        }
                                        setLikeStatus(localStorage.getItem("like_"+roomId));

                                        axios.post(
                                          'https://primesheet.net/query/call',
                                          JSON.stringify({
                                            id:"63c4fe4cbe755ed0cad9ed49",
                                            params: {
                                                room_id: roomId,
                                                inc: likeStatus ? false : true
                                            }
                                          }),
                                          {
                                          headers: {
                                            "Content-Type": "application/json; charset=utf-8",
                                          },
                                        })
                                        .then(function (response) {
                                            setLikeValue(likeStatus ? likeValue+1 : likeValue-1);
                                        });
                                    }}>
                                    <img src={likeStatus ? "./../images/rwe_fill.png" : "./../images/rwe (1).png"} alt="" />
                                    <span className="like-count">{likeValue}</span>
                                </a>
                                <Tooltip anchorId="like" content="좋아요" />
                            </div>
                        </div>
                        <div className="mb-5">
                            <ImageGallery items={data.imgSrc} autoPlay="true" thumbnailPosition="bottom"/>
                        </div>

                        <div className="row">
                            <div className="col-lg-8 mb-4 mb-lg-0">
                                <h3 className="semi-bold font__size--32 text__32-1024 mb-4 ls-1 wb-ws">{data.subTitle}</h3>
                                <div className="d-flex align-items-center flex-wrap">
                                    <div className="d-flex align-items-center mb-4">
                                        <img src="./../images/sdad.png" alt="" />
                                        <span className="medium font__size--16 text__16-1024 color__gray-2 ml-2 ls-1 wb-ws">규정 인원수 이상으로는 입실이 불가할 수 있습니다.</span>
                                    </div>
                                </div>

                                <div className="d-flex flex-wrap flex-md-nowrap">
                                    <div className="d-flex align-items-start mb-4 mb-md-5 mr-4">
                                        <img src="./../images/saf (3).png" alt="" />
                                        <div className="ml-2">
                                            <h5 className="semi-bold font__size--16 text__16-1024 mb-2 ls-1 wb-ws">{data.offer}</h5>
                                            <p className="normal font__size--14 text__14-1024 color__gray-2 lh__4 ls-1">추가요금없이 사용가능합니다.</p>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-start mb-4 mb-md-5 mr-4">
                                        <img src="./../images/profile-tick.png" alt="" />
                                        <div className="ml-2">
                                            <h5 className="semi-bold font__size--16 text__16-1024 mb-2 ls-1">외부음식 반입가능</h5>
                                            <p className="normal font__size--14 text__14-1024 color__gray-2 lh__4 ls-1">외부음식ㆍ음료 / 배달 <br /> 모두 반입가능합니다.</p>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-start mb-4 mb-md-5 mr-4">
                                        <img src="./../images/saf (2).png" alt="" />
                                        <div className="ml-2">
                                            <h5 className="semi-bold font__size--16 text__16-1024 mb-2 ls-1">반려동물 동반가능</h5>
                                            <p className="normal font__size--14 text__14-1024 color__gray-2 lh__4 ls-1">반려동물과 함께 이용하실 수 있습니다.</p>
                                        </div>
                                    </div>
                                </div>

                                <ul className="nav nav-pills wrapper__nav-travel mb-4" id="pills-tab" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link medium font__size--14 text__14-1024 color__gray-2 active ls-1" id="pills-Overview-tab" data-toggle="pill" href="#pills-Overview" role="tab" aria-controls="pills-Overview" aria-selected="true">공간소개</a>
                                    </li>
                                </ul>

                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="pills-Overview" role="tabpanel" aria-labelledby="pills-Overview-tab">
                                        {
                                            data.content.map((obj, index) => {
                                                return <p key={index} className="normal font__size--16 text__16-1024 mb-4 ls-1 wb-ws">{obj}</p>
                                            })

                                        }
                                        <h4 className="semi-bold font__size--32 text__32-1024 my-4 ls-1">시설 안내</h4>
                                        {
                                            accordions.map((obj, index) => {
                                                return <div key={index} className="mb-2 ls-1 wb-ws">
                                                    <AccordionOffer data={obj} />
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-4">
                                <div className="wrapper__detail-reserve">
                                    <h3 className="semi-bold font__size--32 text__32-1024 mb-1 ls-1" id="weekday">{numberFormatter(data.appointedPay[0])}원<span className="medium font__size--20 text__18-1024 ml-1">/{data.appointedPay[0] != data.appointedPay[1] ? '평일' : '평일,주말 및 공휴일'}</span></h3>
                                    <h3 className="semi-bold font__size--32 text__32-1024 mb-1 ls-1" id="weekend" style={{display: data.appointedPay[0] != data.appointedPay[1] ? 'block' : 'none'}}>{numberFormatter(data.appointedPay[1])}원<span className="medium font__size--20 text__18-1024 ml-1">/주말 및 공휴일</span></h3>

                                    <div className="row mb-4 mt-5">
                                        <div className="col-md-6">
                                            <h5 className="medium font__size--14 text__14-1024 ls-1">체크인</h5>
                                            <div className="wrapper__field-date position-relative" onClick={() => datein.current.showPicker()}>
                                                <img src="./../images/calendar-add.png" className="pointer" alt="" />
                                                <input type="date" ref={datein} className="ml-2 ls-1" id="checkIn" onChange={e => {
                                                    setInValue(dateFormat(e.target.value, "mmm dd일 (ddd)"));
                                                    setInValueTime(new Date(e.target.value).setHours(0,0,0,0,0,0));
                                                     calcPrice(gues, startTime, new Date(e.target.value).setHours(0,0,0,0,0,0), useHours);
                                                }} />
                                                <span className="ml-2 medium font__size--16 text__16-1024 ls-1">{inValue}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <div className="col-md-6">
                                            <h5 className="medium font__size--14 text__14-1024 ls-1">시작시각</h5>
                                            <Select options={selectOptions.start} defaultValue={{label:timeConverter(startTime), value:startTime}} id="start" onChange={(e) => {setStartTime(e.value)}} />
                                        </div>
                                        <div className="col-md-6">
                                            <h5 className="medium font__size--14 text__14-1024 ls-1 pt__1half">이용시간</h5>
                                             <Select options={chkBigRoom(data.title) == true ? selectOptions.bigRoomHour : selectOptions.hour} id="hour" defaultValue={{label:timeConverter(useHours, 1), value:useHours}} onChange={(e) => {
                                                setUseHours(e.value); setConvertHours(convertHour(e.value)); calcPrice(gues, startTime, inValueTime, e.value);
                                            }} />
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <h5 className="medium font__size--14 text__14-1024 ls-1">인원선택 (최소{data.peopleCountData[0]}인)</h5>
                                        <div className={"wrapper__select-option position-relative " + (toogleGues ? "active" : "")}>
                                            <div className="d-flex align-items-center justify-content-between pointer" onClick={() => setToogleGues(!toogleGues)}>
                                                <div className="d-flex align-items-center">
                                                    <img src="./../images/profile-2user.png" alt="" />
                                                    <span className="ml-2 medium font__size--16 text__16-1024 ls-1" id="usingPeople" value={gues}>{gues}명</span>
                                                </div>
                                                <img src="./../images/arrow-down.png" alt="" className="arrow" />
                                            </div>
                                            <div className="guest__list meium font__size--16 text__16-1024">
                                            {
                                                data.peopleCountData.map((data, index) => {
                                                    return <div key={index} className="list pointer" onClick={() => {
                                                        setGues(data)
                                                        setToogleGues(!toogleGues)
                                                        calcPrice(data, startTime, inValueTime, useHours)
                                                    }}>{data}명</div>
                                                })
                                            }
                                            </div>
                                        </div>
                                    </div>

                                    <a className="btn btn__blue shadow color__white w-100 rounded__50 medium font__size--16 text__16-1024 ls-1" onClick={()=>{
                                        let chkRsvDate = new Date(inValueTime).setHours(startTime.split(':')[0],startTime.split(':')[1],0,0,0,0);
                                        if(chkRsvDate < new Date().getTime()) return alert("이전 날짜는 예약할 수 없습니다.");

                                        if(chkBigRoom(data.title) && useHours == '1:00') {
                                            return alert('예약은 2시간 이상부터 가능합니다.')
                                        } else {
                                            window.open(`./reservation/reservation.html?room=${roomName}&roomId=${roomId}&people=${gues}&date=${inValueTime}&start=${startTime}&hour=${useHours}`)
                                        } 
                                    } }>예약하기</a>

                                    <div className="my-4">
                                        <p className="normal font__size--14 text__14-1024 color__gray-2 mb-0 ls-1">예상 결제 금액</p>
                                    </div>

                                    <div className="basic-price">
                                        <div className="wrapper__side-detail clear d-flex align-items-center justify-content-between">
                                            <span className="medium font__size--16 text__16-1024 color__gray-2 ls-1">
                                            {
                                                (roomId=="healing" || roomId=="cafe" || roomId=="r5"? "기본 "+data.peopleCountData[0] : gues)
                                                +"명 X "
                                                +numberFormatter((new Date(inValueTime).getDay() == 0 || new Date(inValueTime).getDay() == 6) ? data.appointedPay[1] : data.appointedPay[0])
                                                +"원 X "+convertHours+"시간"  /*15,000원 X 4.5시간*/}</span>
                                            <span className="medium font__size--16 text__16-1024 ls-1" id="basicPrice">{(roomId=="healing" || roomId=="cafe" || roomId=="r5"? basicPrice : totalPrice)}￦</span>
                                        </div>
                                    </div>
                                    <div className="add-people-price">
                                        <div className="wrapper__side-detail clear d-flex align-items-center justify-content-between">
                                            <span className="medium font__size--16 text__16-1024 color__gray-2 ls-1">{"추가 "+(gues-data.peopleCountData[0])+"명 X "+(data.title == "힐링/파티룸"? numberFormatter(3000) : ((data.title == "카페룸" || data.title.indexOf("R5") > -1)? numberFormatter(2000): numberFormatter(data.appointedPay[0])) )+"원 X "+convertHours+"시간"}</span>
                                            <span className="medium font__size--16 text__16-1024 ls-1" id="addPeoplePrice">{addPeoplePrice}￦</span>
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <div className="wrapper__side-detail d-flex align-items-center justify-content-between">
                                            <span className="medium font__size--16 text__16-1024 color__gray-2 ls-1">예상금액</span>
                                            <span className="medium font__size--16 text__16-1024 ls-1" id="totalPrice">{totalPrice}￦</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <Footer />
            </div >
        </Fragment >
    );
};

export default Detail;
