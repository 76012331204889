import React, { useState, Fragment, useRef } from "react";
import { NavLink } from "react-router-dom";
import CardTrip from "../../component/Card/CardTrip";
import Filter from "../../component/Modal/FIlter";
import Footer from "../../component/Other/Footer";
import Navbar from "../../component/Other/Navbar";
import Navstep from "../../component/Other/Navstep";
const Destination = (props) => {
    const destinations = [
        {
            id: "healing",
            img: "./../images/healing/h2.jpg",
            title: "힐링/파티룸",
            location: "최대 15-20인",
            rating: "4인기준",
            date: "평일",
            price: "15,000",
        },
        {
            id: "cafe",
            img: "./../images/cafe/m1.jpg",
            title: "카페룸",
            location: "최대 20인",
            rating: "6인기준",
            date: "평일/주말",
            price: "15,000",
        },
        {
            id: "r5",
            img: "./../images/r5/m1.jpg",
            title: "10인룸 VIP(R5)",
            location: "최대 10인",
            rating: "4인기준",
            date: "평일",
            price: "12,000",
        },
        {
            id: "r6",
            img: "./../images/r6/r6.jpg",
            title: "12인룸 R6",
            location: "최대 12인",
            rating: "6인기준",
            date: "평일",
            price: "1,500",
        },
        /*{
            id: "r1",
            img: "./../images/r1/m1.jpg",
            title: "8인룸 R1",
            location: "최대 8인",
            rating: "3인기준",
            date: "평일",
            price: "1,500",
        },*/
        {
            id: "r2",
            img: "./../images/r2/m1.jpg",
            title: "8인룸 R2",
            location: "최대 8인",
            rating: "3인기준",
            date: "평일",
            price: "1,500",
        },
        /*{
            id: "r8",
            img: "./../images/r8/m2.jpg",
            title: "8인룸 R8",
            location: "최대 8인",
            rating: "3인기준",
            date: "평일",
            price: "1,500",
        },*/
        {
            id: "r3",
            img: "./../images/r3/m3.jpg",
            title: "4인룸 R3",
            location: "최대 4인",
            rating: "2인기준",
            date: "평일",
            price: "1,500",
        },
        {
            id: "r4",
            img: "./../images/r4/m3.jpg",
            title: "4인룸 R4",
            location: "최대 4인",
            rating: "2인기준",
            date: "평일",
            price: "1,500",
        },
        {
            id: "r7",
            img: "./../images/r7/m2.jpg",
            title: "4인룸 R7",
            location: "최대 4인",
            rating: "2인기준",
            date: "평일",
            price: "1,500",
        },
        /*{
            id: "mYellow",
            img: "./../images/mini/m2.jpg",
            title: "미니룸(옐로우)",
            location: "최대 2인",
            rating: "1인기준",
            date: "평일",
            price: "1,500",
        },*/
        /*{
            id: "mOrange",
            img: "./../images/mini/m5.jpg",
            title: "미니룸(오렌지)",
            location: "최대 2인",
            rating: "1인기준",
            date: "평일",
            price: "1,500",
        }*//*,
        {
            id: "mBlue",
            img: "./../images/mini/m8.jpg",
            title: "미니룸(블루)",
            location: "최대 2인",
            rating: "1인기준",
            date: "평일",
            price: "1,500",
        }*/
    ]

    const [modalFilter, setModalFilter] = useState(false);
    const onCLickModalFilter = () => {
        if (!modalFilter) {
            document.body.classList.add("modal-open");
        } else {
            document.body.classList.remove("modal-open");
        }
        setModalFilter(!modalFilter);
    };

    return (
        <Fragment>
            <Filter
                modalFilter={modalFilter}
                onCLickModalFilter={() => onCLickModalFilter()} />
            <div className="overflow-hidden">
                <Navbar />

                <section>
                    <div className="container">
                        <Navstep now={"공간정보"} />
                        <div className="mt-4 mb-5">
                            <h2 className="semi-bold font__size--48 text__48-1024 text__48-md text__48-sm mb-3 ls-1">공간잇의 공간을 소개합니다!</h2>
                            <div className="d-flex align-items-center">
                                <div className="wrapper__rounded-green color__green semi-bold font__size--14 text__14-1024 ws__nowrap">
                                    2인~20인
                                </div>
                                <span className="medium font__size--14 text__14-1024 ml-2 color__gray-2 ls-1 wb-ws">모든 종류의 모임들이 함께할 수 있는 공간을 만들어갑니다</span>
                            </div>
                        </div>

                        <div className="d-flex align-items-start flex-sm-nowrap flex-wrap justify-content-between mb-4">
                            <ul className="nav nav-pills wrapper__nav-travel mb-4 mb-lg-0" id="pills-tab" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link medium font__size--14 text__14-1024 color__black active ls-1" id="pills-Popular-tab" data-toggle="pill" href="#pills-Popular" role="tab" aria-controls="pills-Popular" aria-selected="true">All</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link medium font__size--14 text__14-1024 color__black ls-1" id="pills-Islands-tab" data-toggle="pill" href="#pills-Islands" role="tab" aria-controls="pills-Islands" aria-selected="false">카페/힐링/파티룸</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link medium font__size--14 text__14-1024 color__black ls-1" id="pills-Surfing-tab" data-toggle="pill" href="#pills-Surfing" role="tab" aria-controls="pills-Surfing" aria-selected="false">6~12인</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link medium font__size--14 text__14-1024 color__black ls-1" id="pills-Nation-tab" data-toggle="pill" href="#pills-Nation" role="tab" aria-controls="pills-Nation" aria-selected="false">4~10인</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link medium font__size--14 text__14-1024 color__black ls-1" id="pills-Lake-tab" data-toggle="pill" href="#pills-Lake" role="tab" aria-controls="pills-Lake" aria-selected="false">3~8인</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link medium font__size--14 text__14-1024 color__black ls-1" id="pills-Beach-tab" data-toggle="pill" href="#pills-Beach" role="tab" aria-controls="pills-Beach" aria-selected="false">1~4인</a>
                                </li>
                                {/*<li className="nav-item">
                                    <a className="nav-link medium font__size--14 text__14-1024 color__black ls-1" id="pills-Camp-tab" data-toggle="pill" href="#pills-Camp" role="tab" aria-controls="pills-Camp" aria-selected="false">1~2인</a>
                                </li>*/}
                            </ul>
                            {/*<div onClick={() => onCLickModalFilter()} className="pointer btn__filter d-flex align-items-center">
                                <span className="medium font__size--14 text__14-1024 mr-2 ls-1">Filters</span>
                                <img src="./../images/setting-4.png" alt="" />
                            </div>*/}
                        </div>
                        <div className="tab-content mb-4" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-Popular" role="tabpanel" aria-labelledby="pills-Popular-tab">
                                <div className="row">
                                    {
                                        destinations.map((obj, index) => {
                                            return <div key={index} className="col-sm-6 col-lg-3 mb-4 mb-lg-0">
                                                <CardTrip data={obj} />
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                            <div className="tab-pane fade" id="pills-Islands" role="tabpanel" aria-labelledby="pills-Islands-tab">
                                <div className="row">
                                    {
                                        destinations.map((obj, index) => {
                                            if(obj.location.indexOf('20인') > -1 || obj.location.indexOf('10인') > -1) {
                                                return <div key={index} className="col-sm-6 col-lg-3 mb-4 mb-lg-0">
                                                    <CardTrip data={obj} />
                                                </div>
                                            }
                                        })
                                    }
                                </div>
                            </div>
                            <div className="tab-pane fade" id="pills-Surfing" role="tabpanel" aria-labelledby="pills-Surfing-tab">
                                <div className="row">
                                    {
                                        destinations.map((obj, index) => {
                                            if(obj.location.indexOf('12인') > -1) {
                                                return <div key={index} className="col-sm-6 col-lg-3 mb-4 mb-lg-0">
                                                    <CardTrip data={obj} />
                                                </div>
                                            }
                                        })
                                    }
                                </div>
                            </div>
                            <div className="tab-pane fade" id="pills-Nation" role="tabpanel" aria-labelledby="pills-Nation-tab">
                                <div className="row">
                                    {
                                        destinations.map((obj, index) => {
                                            if(obj.location.indexOf('10인') > -1) {
                                                return <div key={index} className="col-sm-6 col-lg-3 mb-4 mb-lg-0">
                                                    <CardTrip data={obj} />
                                                </div>
                                            }
                                        })
                                    }
                                </div>
                            </div>
                            <div className="tab-pane fade" id="pills-Lake" role="tabpanel" aria-labelledby="pills-Lake-tab">
                                <div className="row">
                                    {
                                        destinations.map((obj, index) => {
                                            if(obj.location.indexOf('8인') > -1) {
                                                return <div key={index} className="col-sm-6 col-lg-3 mb-4 mb-lg-0">
                                                    <CardTrip data={obj} />
                                                </div>
                                            }
                                        })
                                    }
                                </div>
                            </div>
                            <div className="tab-pane fade" id="pills-Beach" role="tabpanel" aria-labelledby="pills-Beach-tab">
                                <div className="row">
                                    {
                                        destinations.map((obj, index) => {
                                            if(obj.location.indexOf('4인') > -1) {    
                                                return <div key={index} className="col-sm-6 col-lg-3 mb-4 mb-lg-0">
                                                    <CardTrip data={obj} />
                                                </div>
                                            }
                                        })
                                    }
                                </div>
                            </div>
                            {/*<div className="tab-pane fade" id="pills-Camp" role="tabpanel" aria-labelledby="pills-Camp-tab">
                                <div className="row">
                                    {
                                        destinations.map((obj, index) => {
                                            if(obj.location.indexOf('최대 2인') > -1) {    
                                                return <div key={index} className="col-sm-6 col-lg-3 mb-4 mb-lg-0">
                                                    <CardTrip data={obj} />
                                                </div>
                                            }
                                        })
                                    }
                                </div>
                            </div>*/}
                        </div>
                    </div>
                </section>
                <Footer />
            </div >
        </Fragment >
    );
};

export default Destination;
