import React, { Component, Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";

/*import Login from './../Modal/Auth/Login'
import Register from './../Modal/Auth/Register';
import Confirm from './../Modal/Auth/Confirm';
import Code from "./../Modal/Auth/Code"*/
import PersonalMembership from "../../component/Modal/PersonalMembership";
import GroupMembership from "../../component/Modal/GroupMembership";

const Navbar = (props) => {

  const imageStyle = {minWidth: 27, minHeight: 27};
  const [modalLogin, setModalLogin] = useState(false);
  const onCLickModalLogin = () => {
    console.log(!modalLogin);
    if (!modalLogin) {
      console.log("add");
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    setModalLogin(!modalLogin);
  };

  const [modalRegister, setModalRegister] = useState(false);
  const onCLickModalRegister = () => {
    if (!modalRegister) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    setModalRegister(!modalRegister);
  };


  const [modalConfirm, setModalConfirm] = useState(false);
  const onCLickModalConfirm = () => {
    if (!modalConfirm) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    setModalConfirm(!modalConfirm);
  };

  const [modalCode, setModalCode] = useState(false);
  const onCLickModalCode = () => {
    if (!modalCode) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    setModalCode(!modalCode);
  };

  const [modalPersonal, setModalPersonal] = useState(false);
  const onCLickModalPersonal = () => {
    if (!modalPersonal) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    setModalPersonal(!modalPersonal);
  };

  const [modalGroup, setModalGroup] = useState(false);
  const onCLickModalGroup = () => {
    if (!modalGroup) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    setModalGroup(!modalGroup);
  };

  const onCLickSwitchModal = async (e) => {
    if (e == "login") {
      setModalRegister(!modalRegister);
      const timeout = await setTimeout(() => {
        setModalLogin(!modalLogin);
      }, 1000);
      return () => clearInterval(timeout);
    }
    if (e == "register") {
      setModalLogin(!modalLogin);
      const timeout = await setTimeout(() => {
        setModalRegister(!modalRegister);
      }, 1000);
      return () => clearInterval(timeout);
    }
    if (e == "confirm") {
      if (modalLogin) {
        setModalLogin(!modalLogin);
      }
      if (modalRegister) {
        setModalRegister(!modalRegister);
      }
      const timeout = await setTimeout(() => {
        setModalConfirm(!modalConfirm);
      }, 1000);
      return () => clearInterval(timeout);
    }

    if (e == "code") {
      setModalConfirm(!modalConfirm);
      const timeout = await setTimeout(() => {
        setModalCode(!modalCode);
      }, 1000);
      return () => clearInterval(timeout);
    }
  };



  const [toogleMenu, setToogleMenu] = useState(false)
  const [toogleLanguage, setToogleLanguage] = useState("English")

  const status = localStorage.getItem("status") == "login" ? true : false

  const onLogout = () => {
    localStorage.removeItem("status");
  }

  return (
    <Fragment>

      {/*<Login
        modalLogin={modalLogin}
        onCLickModalLogin={() => onCLickModalLogin()}
        onCLickSwitchModal={(e) => onCLickSwitchModal(e)}
      />
      <Register
        modalRegister={modalRegister}
        onCLickModalRegister={() => onCLickModalRegister()}
        onCLickSwitchModal={(e) => onCLickSwitchModal(e)}
      />
      <Confirm
        modalConfirm={modalConfirm}
        onCLickModalConfirm={() => onCLickModalConfirm()}
        onCLickSwitchModal={(e) => onCLickSwitchModal(e)}
      />
      <Code
        modalCode={modalCode}
        onCLickModalCode={() => onCLickModalCode()}
        onCLickSwitchModal={(e) => onCLickSwitchModal(e)}
      />*/}

      <PersonalMembership
        modalPersonal={modalPersonal}
        onCLickModalPersonal={() => onCLickModalPersonal()} />

      <GroupMembership
      modalGroup={modalGroup}
      onCLickModalGroup={() => onCLickModalGroup()} />

      <div className={"wrapper__side-nav-mobile d-flex d-lg-none " + (toogleMenu ? "active" : "")}>
        <div className="d-flex flex-wrap w-100">
          <div className="w-100">
            <div className="d-flex align-items-center justify-content-between mb-4">
              <NavLink
                to="/"
                exact
                className="navbar-brand medium font__size--20 text__20-1024 bold "
              >
                <div className="d-flex align-items-center font__size--18 text__18-1024 semi-bold color__white">
                  <img src="./../images/ggit_logo_white_small.png" alt="" />
                </div>
              </NavLink>
              <img src="./../images/close (1).png" onClick={() => setToogleMenu(!toogleMenu)} className="pointer" alt="" />
            </div>
            <div className="menu">
              <NavLink to="/" className="semi-bold font__size--16 text__16-1024 text__16-1024 color__white">소개</NavLink>
              <hr />
              <NavLink to="/destination" className="semi-bold font__size--16 text__16-1024 text__16-1024 color__white">공간정보</NavLink>
              <hr />
              <NavLink
                to="/membership" className="semi-bold font__size--16 text__16-1024 text__16-1024 color__white">멤버십</NavLink>
              <hr />
              <NavLink to="/faq" className="semi-bold font__size--16 text__16-1024 text__16-1024 color__white">FAQ</NavLink>
              <hr />
            </div>
          </div>
        </div>
      </div >
      <div className="bg__wrap-menu d-lg-none" onClick={() => setToogleMenu(!toogleMenu)}></div>
      <nav
        className="navbar navbar-expand-lg wrapper__navbar position-relative z-2"
        data-aos="fade-down"
        data-aos-duration="1000"
        data-aos-delay="300"
      >
        <div className="container position-relative">
          <div className="d-flex align-items-center">
            <div
              onClick={() => setToogleMenu(!toogleMenu)}
              className="nav__button d-lg-none position-relative mr-1 mr-sm-3 flex-shrink-0"
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
            <NavLink
              to="/"
              exact
              className="navbar-brand font__size--18 text__18-1024 semi-bold  color__black"
            >
              <div className="d-flex align-items-center">
                <img src="./../images/ggit_logo.svg" alt="" className="mobile-header-logo" />
              </div>
            </NavLink>
          </div>

          <div
            className="collapse navbar-collapse wrapper__navbar-menu ml-lg-5"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav menu__center">
              <li className="nav-item">
                <NavLink
                  to="/"
                  exact
                  className="nav-link medium font__size--16 text__16-1024 text__16-1024 ls-1 color__gray-1"
                >
                  소개
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/destination"
                  className="nav-link medium font__size--16 text__16-1024 text__16-1024 ls-1 color__gray-1"
                >
                  공간정보
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/membership"
                  className="nav-link medium font__size--16 text__16-1024 text__16-1024 ls-1 color__gray-1"
                >
                  멤버십
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/faq"
                  className="nav-link medium font__size--16 text__16-1024 text__16-1024 ls-1 color__gray-1"
                >
                  FAQ
                </NavLink>
              </li>
            </ul>
          </div>


          <div className="wrapper__side-menu d-flex align-items-center justify-content-center">
            
            <a className="" href="../reservation/reservation.html" role="button" id="dropdownMenuLink" target="_blank">
              <div className="d-flex align-items-center">
                <img className="ggit-book" src="./../images/ggit_book.svg" alt="" style={imageStyle}/>
                <span className="medium font__size--16 text__16-1024 ml-1 color__gray-1 d-none d-sm-block ls-1">실시간예약</span>
              </div>
            </a>

            <a href="https://talk.naver.com/ct/w4qi2v?frm=mnmb&frm=nmb_detail" target="_blank" className="notification position-relative mx-2 mx-sm-3">
              {/*<div className="circle normal font__size--14 color__white">5</div>*/}
              <img className="navertalk-btn" src="./../images/navertalk.png" alt="" />
            </a>


            <div className="dropdown wrapper__dropdown">
              <div className="pointer" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <img src="./../images/membership.png" className="images__membership" alt="" />
              </div>
              <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                <div className="d-flex align-items-center justify-content-between dropdown-item" onClick={() => onCLickModalPersonal()}>
                  <div className="d-flex align-items-center">
                    <img src="./../images/sadfg (1).png" alt="" />
                    <span className="medium font__size--16 text__16-1024 ls-1 ml-2">개인 멤버십 조회</span>
                  </div>
                  {/*<div className="wrapper__count normal font__size--14">N</div>*/}
                </div>
                <div className="d-flex align-items-center justify-content-between dropdown-item" onClick={() => onCLickModalGroup()}>
                  <div className="d-flex align-items-center">
                    <img src="./../images/Notification - Bell (3).png" alt="" />
                    <span className="medium font__size--16 text__16-1024 ls-1 ml-2">단체 멤버십 조회</span>
                  </div>
                  <div className="wrapper__count normal font__size--14">
                    N
                  </div>
                </div>
                <a className="dropdown-item" href="https://open.kakao.com/o/sy2H16Sd" target="_blank">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <img src="./../images/sadfg (4).png" alt="" />
                      <span className="medium font__size--16 text__16-1024 ls-1 ml-2">멤버십 전용채널</span>
                    </div>
                    <div className="wrapper__count normal font__size--14">
                      N
                    </div>
                  </div>
                </a>
                <div className="hr__line">
                  <hr className="my-3" />
                </div>
                <a className="dropdown-item" href="#">
                  <div className="d-flex align-items-center" onClick={() => {window.open('/Inconvenience')}}>
                    <img src="./../images/Notification - Bell (5).png" alt="" />
                    <span className="medium font__size--16 text__16-1024 ls-1 ml-2">불편사항 접수</span>
                  </div>
                </a>
                <NavLink to="/membership" className="profile-user color__black mt-4">
                  <div href="#!" className="btn btn__outlined--blue shadow medium ls-1 font__size--16 text__16-1024 color__blue no__opacity w-100 rounded__50" onClick={() => {window.open('/membership'); }} target="_blank">멤버십 가입문의</div>
                </NavLink>
              </div>
            </div>
          </div>


        </div>
      </nav>
    </Fragment >
  );
};

export default Navbar;
