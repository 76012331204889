import React, { Component } from 'react'
import { Fragment } from 'react'

const Footer = (props) => {
    return (
        <Fragment>
            <section className='py-5'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 mb-4 my-md-auto text-center text-md-left">
                            <div className="d-flex align-items-center justify-content-md-start justify-content-center mb-3">
                                <img src="./../images/ggit_logo.svg" alt="" />
                            </div>
                            <p className='normal font__size--16 text__16-1024 color__gray-2 ls-1'>전화예약/문의 1599-3687<br /> 영업시간  00:00 ~ 24:00<br /> 예약 안내 및 상담 시간  10:00 ~ 22:00 </p>

                            <div className="d-flex align-items-center justify-content-md-start justify-content-center mt-4">                                
                                <a href="https://www.instagram.com/gonggan_it/" className='d-inline-block mr-3' target="_blank">
                                    <img src="./../images/instagram.png" alt="" />
                                </a>                                
                                {/*<a href="#!" className='d-inline-block mr-3'>
                                    <img src="./../images/twitter.png" alt="" />
                                </a>*/}
                            </div>
                        </div>
                        <div className="col-md-6 my-auto text-center text-md-right">
                            <div className="wrapper__list-menu justify-content-md-end justify-content-center medium font__size--16 text__16-1024 mb-4">
                                <a href="/" className='color__black'>소개</a>
                                <a href="/destination" className='color__black'>공간정보</a>
                                <a href="/membership" className='color__black'>멤버십</a>
                                <a href="/faq" className='color__black'>FAQ</a>
                            </div>
                            <h5 className='medium font__size--16 text__16-1024 mb-3 ls-1'>Copyright © 2022 NOVUS Co. Ltd. All rights reserved.</h5>
                            <div className="d-inline-block">
                                <a href="#!" className="medium font__size--16 text__16-1024 btn btn__blue shadow color__white rounded__50">(주)노버스 홈페이지</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default Footer