import React, { Component } from 'react'
import { Fragment } from 'react'
import { NavLink } from 'react-router-dom'

const CardTrip = (props) => {
    let priceDsc = "1인";
    if(props.data.title.indexOf('힐링') > -1) {priceDsc = "1시간";}
    else if(props.data.title.indexOf('카페') > -1) {priceDsc = "1시간";}
    else if(props.data.title.indexOf('R5') > -1) {priceDsc = "1시간";}
    /*else if(props.data.title.indexOf('R6') > -1) {priceDsc = "1시간";}*/

    return (
        <Fragment>
            <NavLink to={"/detail?id="+props.data.id} className="wrapper__card-trip color__black d-inline-block w-100">
                <img src={props.data.img} className='img' alt="" />
                <div className="desc">
                    <div className="d-flex align-items-start justify-content-between">
                        <div>
                            <h5 className='medium font__size--20 text__20-1024 mb-0 ls-1'>{props.data.title}</h5>
                            <p className='mb-0 normal font__size--16 text__16-1024 color__gray-3 mb-0 ls-1'>{props.data.location}</p>
                        </div>
                        <div className="d-flex align-items-center">
                            <img src="./../images/profile-2user.png" alt="" />
                            <span className='medium font__size--16 text__16-1024 ml-2'>{props.data.rating}</span>
                        </div>
                    </div>
                    <hr />
                    <div className="d-flex align-items-center justify-content-between">
                        <span className='normal font__size--16 text__16-1024 color__gray-1'>{props.data.date}</span>
                        <span className='medium font__size--24 text__24-1024'>{props.data.price}￦<span className='medium font__size--12'>/{priceDsc}</span></span>
                    </div>
                </div>
            </NavLink>
        </Fragment>
    )
}

export default CardTrip